// @ts-ignore
window.COS_CONFIG = {
  PUBLIC: 'tanlive-public-1310777349',
  PUBLIC_URL: 'https://tanlive-public-1310777349.cos.ap-guangzhou.myqcloud.com',
  PRIVATE: 'tanlive-private-1310777349',
  PRIVATE_URL: 'tanlive-private-1310777349.cos.ap-guangzhou.myqcloud.com',
  CDN_URL: 'https://file.tanlive.com',
  CDN_HELP_CENTER: 'https://file.tanlive.com/helpcentertestpredoc2/livedoc/',
  REGION: 'ap-guangzhou',
};

window.RUM_CONFIG = {
  enable: true,
  APPID: 'qVK57hLD487dWRPl50'
};

// @ts-ignore
window.LOGOUT_URL = '/logout?redirect=https://tanlive.com/logout?redirect=https://tanlive.cig.tencentcs.com/logout?return_to=https://carbon.tencent.com/';

// @ts-ignore
window.LOGIN_URL = 'https://tanlive-idp.cig.tencentcs.com/sso/tn-fb24ab7b259b4ca09c918b14d328fbb9/ai-bbc8f4e866dc40778500c166452e3881/oidc/authorize?redirect_uri=https://carbon.tencent.com/api/iam/oauth2/link_callback&client_id=ai-bbc8f4e866dc40778500c166452e3881&scope=all&response_type=code&from=idp&state=carbonlab';

// @ts-ignore
window.PROFILE_URL = 'https://tanlive.com/console/account-indv/';